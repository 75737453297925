import React, { useState } from 'react';
import Button from '../Button';

interface Props {
  handleSubmit: (note: string) => void;
  theirName: string;
  buttonLabel: string;
}

const Note = (props: Props) => {
  const [note, setNote] = useState('');

  const onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setNote(e.currentTarget.value);
  }
  return (
    <>
      <p>Share a note</p>
      <p>Let {props.theirName} know why you don't want to connect</p>
      <textarea onChange={onChange} placeholder="Write a note here" value={note}/>
      <Button onClick={() => props.handleSubmit(note)}>{props.buttonLabel}</Button>
    </>
  );
};

export default Note;
