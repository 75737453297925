import React from 'react';
import ThumbsUpIcon from '../../assets/thumbsup.svg';
import ThumbsDownIcon from '../../assets/thumbsdown.svg';
import ThumbButton from '../ThumbButton';
import TextButton from '../TextButton';

interface Props {
  handleDidntHappen?: () => void;
  handleThumbsUp: () => void;
  handleThumbsDown: () => void;
  firstName: string;
}

const FirstStep = (props: Props) => {
  return (
    <>
      <p><b>Do you want to exchange contact cards?</b></p>
      <p>Your full name and phone number will be shared with {props.firstName}, but only if they choose to do the same</p>
      <div>
        <ThumbButton onClick={props.handleThumbsDown} >
          <img src={ThumbsDownIcon}  alt="Thumbs down"/>
        </ThumbButton>
        <ThumbButton onClick={props.handleThumbsUp} >
          <img src={ThumbsUpIcon}  alt="Thumbs up"/>
        </ThumbButton>
      </div>
      <TextButton onClick={props.handleDidntHappen}>
        This date didn't happen ›
      </TextButton>
    </>
  );
};

export default FirstStep;
