import React from 'react';
import PreDateContent from './PreDateContent';
import Header from './Header';
import Button from './Button';
import Buttons from './Buttons';
import { ServerPayload } from '../Types';

interface InitialProps {
  data: ServerPayload;
  startDate: () => void;
  resume?: boolean;
}

const Initial = (props: InitialProps) => {
  return (
    <>
      <PreDateContent data={props.data}>
        <Header>Video date with {props.data.firstName}</Header>
        <Button onClick={props.startDate}>{props.resume ? 'Resume' : 'Join'} Video Date</Button>
      </PreDateContent>
      <Buttons thumbnailUrl={props.data.thumbnailUrl} enabled={false}></Buttons>
    </>
  )
 };

export default Initial;
