import React,  { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Colours from '../Styles/Colours';

interface TimerProps {
  mostRecentConnectionStartedAt: number | null;
  remainingSeconds: number;
  onReachZero: ()=>void;
}

const Timer = ({ mostRecentConnectionStartedAt , remainingSeconds , onReachZero}: TimerProps) => {
  const [currentRemainingSeconds, setCurrentRemainingSeconds] = useState<number>(remainingSeconds);
  useEffect(() => {
    const interval = setInterval(()=> {
      if (!mostRecentConnectionStartedAt) {
        return;
      }
      const secondsElapsed = (new Date().getTime() - mostRecentConnectionStartedAt!) / 1000;
      const newValue = remainingSeconds - secondsElapsed;
      setCurrentRemainingSeconds(remainingSeconds - secondsElapsed);

      if (newValue <= 0) {
        onReachZero();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [mostRecentConnectionStartedAt, remainingSeconds, onReachZero]);

  let text = "Time's up"

  if (currentRemainingSeconds > 0) {
    const minutes = Math.floor(currentRemainingSeconds / 60);
    const seconds = Math.floor(currentRemainingSeconds % 60);
    text = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  return (
    <TimerDiv>
      {text}
    </TimerDiv>
  )
}

const TimerDiv = styled.div `
   position: absolute;
   top: -120px;
   left: 50px;
   width: 100px;
   font-weight: bold;
   color: white;
   padding: 20px;
   text-align: center;
   border-radius: 20px;
   background-color: ${Colours.warning};
   margin: auto;
 `

 export default Timer;
