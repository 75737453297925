import React, { useState } from 'react';
import Button from '../Button'

interface Props {
  handleComplete: (issue: string | null) => void;
}

const DateIssues = (props: Props) => {
  const [issue, setIssue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);

  const onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setIssue(e.currentTarget.value);
  }

  const renderIssueInput = () => (
    <>
      <p>Sorry to hear that. What was the problem?</p>
      <textarea onChange={onChange} value={issue} />
      <Button onClick={() => props.handleComplete(issue)}>Continue</Button>
    </>
  );

  return (
    <>
      {inputVisible ? (
        renderIssueInput()
      ) : (
        <>
          <p>Was there any particular issue?</p>
          <Button onClick={() => setInputVisible(true)}>Yes</Button>
          <Button buttonStyle='secondary' onClick={() => props.handleComplete(null)}>No</Button>
        </>
      )}
    </>
  );
};

export default DateIssues;
