import React, { useEffect, useState, useRef } from 'react';
import ActionCable from 'actioncable';
import WhiteRectangle from './WhiteRectangle';
import ProfileImage from './ProfileImage';
import Header from './Header';
import Button from './Button';
import Buttons from './Buttons';
import { ServerPayload } from '../Types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import Colours from '../Styles/Colours';

interface ExtendProps {
  data: ServerPayload;
  result: (extend: boolean) => void;
}

const Extend = (props: ExtendProps) => {
  const { apiKey, dateId, thumbnailUrl, firstName } = props.data;
  const result = props.result;
  const [subscription, setSubscription] = useState<any>(null);
  const [dateWantsExtension, setDateWantsExtension] = useState<boolean | null>(null);
  const [iWantExtension, setIWantExtension] = useState<boolean | null>(null);
  const subscriptionRef = useRef<any>();
  subscriptionRef.current = subscription;

  const choose = (wantExtension: boolean) => {
    setIWantExtension(wantExtension);
    subscriptionRef.current.perform('extension_response', { extend: wantExtension });
  }

  useEffect(()=>{
    if (subscription || !apiKey) {
      return;
    }
    const newCable = ActionCable.createConsumer(`${process.env.REACT_APP_WS_ENDPOINT}?api_key=${apiKey}`);

    const newSubscription = newCable.subscriptions.create(
      { channel: 'IrlDatesChannel', id: dateId },
      {
        received: (data) => {
          if (data.message_type === 'extension_response') {
            setDateWantsExtension(data.payload.extend);
          }
        }
    });

    setSubscription(newSubscription);
  }, [apiKey, subscription, dateId]);

  useEffect(() => {
    if (dateWantsExtension === false || iWantExtension === false) {
      result(false);
    } else if (dateWantsExtension && iWantExtension) {
      result(true);
    }
  }, [iWantExtension, dateWantsExtension, result]);

  useEffect(() => {
    const countdown = setTimeout(()=> {
      choose(false);
    }, 15000);
    return () => clearTimeout(countdown);
  }, []);

  return (
    <>
      <WhiteRectangle>
        <ProfileImage thumbnailUrl={thumbnailUrl } />
        <Header>Do you want to keep going?</Header>
        { iWantExtension === null ? (
        <><AnimatingButton onClick={() => choose(true)}>
            <Background>Keep going</Background>
            <ForegroundHolder>
              <Foreground>Keep going</Foreground>
            </ForegroundHolder>
           </AnimatingButton>
        <Button buttonStyle={'secondary'} onClick={() => choose(false)}>End date</Button></>
        ) : (<Header>Waiting for {firstName} to respond...</Header>)}
      </WhiteRectangle>
      <Buttons thumbnailUrl={thumbnailUrl} enabled={false}></Buttons>
    </>
  );
}

const grow = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`

const AnimatingButton = styled.button `
  width: 222px;
  height: 50px;
  position: relative;
  border: 0;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 0;
  overflow: hidden;
`;

const Background = styled.div `
  width: 100%;
  height: 100%;
  background-color: ${Colours.default};
  padding: 1.5rem;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
`
const ForegroundHolder = styled.div `
   overflow: hidden;
   animation: ${grow} 14s linear 0s;
   width: 100%;
   height: 50px;
   position: absolute;
   top: 0;
   right: 0;
`;

const Foreground = styled.div `
  width: 222px;
  height: 50px;
  border: 0;
  background-color: ${Colours.disabled};
  padding: 1.5rem;
  color: ${Colours.default};
  position: absolute;
  top: 0;
  right: 0;
`

export default Extend
