import React, { useState } from 'react';
import Button from '../Button';

interface Props {
  handleSubmit: (issue: string) => void;
  message: string;
}

const VideoInput = (props: Props) => {
  const [issue, setIssue] = useState('');

  const onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setIssue(e.currentTarget.value);
  }

  return (
    <>
      <p>{props.message}</p>
      <textarea onChange={onChange} value={issue} />
      <Button onClick={() => props.handleSubmit(issue)}>Continue</Button>
    </>
  );
};

export default VideoInput;
