import React, { useState, useEffect, useRef } from 'react';

import '@opentok/client';
import { OTPublisher, OTSubscriber, OTSession, OTStreams } from 'opentok-react';
import { ServerPayload } from '../Types';
import styled from '@emotion/styled';
import Colours from '../Styles/Colours';
import VideoPausedOverlay from './VideoPausedOverlay';

interface VideoProps {
  data: ServerPayload;
  startTimer: () => void;
  stopTimer: () => void;
  showIcebreaker: (icebreaker: string) => void;
  terminateDate: (extend: boolean) => void;
  sendIcebreakerIndicator: number;
  sendEndSignal: number;
  extend: boolean;
  publishVideo: boolean;
  publishAudio: boolean;
}

interface Session {
  signal: (content: any, callback: (error: any) => void) => void;
}

interface SessionHelper {
  session: Session;
}

interface OTSessionInterface {
  sessionHelper: SessionHelper;
}

const Video = (props: VideoProps) => {
  const [otherPersonConnected, setOtherPersonConnected] = useState<boolean>(
    false
  );
  const [
    previousSendIcebreakerIndicator,
    setPreviousSendIcebreakerIndicator,
  ] = useState<number>(props.sendIcebreakerIndicator);
  const [
    previousSendEndSignalIndicator,
    setPreviousSendEndSignalIndicator,
  ] = useState<number>(props.sendEndSignal);
  const [permissionsDenied, setPermissionsDenied] = useState<boolean>(false);
  const [isMyDatesVideoEnabled, setIsMyDatesVideoEnabled] = useState(true);
  const {
    data,
    sendIcebreakerIndicator,
    showIcebreaker,
    terminateDate,
    extend,
    sendEndSignal,
  } = props;
  const onError = (error: any) => {
    console.log('ERROR!', error.toString());
  };

  const otSession = useRef<OTSessionInterface>();

  useEffect(() => {
    if (!otSession?.current) {
      return;
    }
    if (sendIcebreakerIndicator > previousSendIcebreakerIndicator) {
      let randomIcebreaker =
        data.icebreakers[Math.floor(Math.random() * data.icebreakers.length)];
      otSession!.current!.sessionHelper.session.signal(
        {
          type: 'icebreaker',
          data: JSON.stringify({ icebreaker: randomIcebreaker }),
        },
        (error: any) => {
          if (error) {
            console.log('signal receive error: ' + error.message);
          }
        }
      );
      showIcebreaker(randomIcebreaker);
      setPreviousSendIcebreakerIndicator(sendIcebreakerIndicator);
    }
  }, [
    sendIcebreakerIndicator,
    previousSendIcebreakerIndicator,
    otSession,
    data,
    showIcebreaker,
  ]);

  useEffect(() => {
    if (!otSession?.current) {
      return;
    }

    if (sendEndSignal > previousSendEndSignalIndicator) {
      otSession!.current!.sessionHelper.session.signal(
        {
          type: 'terminateDate',
          data: JSON.stringify({ terminateDate: true, extend: extend }),
        },
        (error: any) => {
          if (error) {
            console.log('signal send error: ' + error.message);
          }
        }
      );

      setPreviousSendEndSignalIndicator(sendEndSignal);
    }
  }, [sendEndSignal, previousSendEndSignalIndicator, otSession, extend]);

  const onPublish = () => {
    console.log('Publish Success');
  };

  const onSubscribe = () => {
    console.log('Subscribe Success');
  };

  const sessionEventHandlers = {
    signal: (event: any) => {
      try {
        const info = JSON.parse(event.data);
        if (info.icebreaker) {
          showIcebreaker(info.icebreaker);
        }

        if (info.terminateDate) {
          terminateDate(info.extend);
        }
      } catch (e) {
        console.log('Parse failure', event.data);
      }
    },
  };

  const publisherEventHandlers = {
    accessDenied: () => {
      console.log('User denied access to media source');
      setPermissionsDenied(true);
    },
    streamCreated: () => {
      console.log('Publisher stream created');
    },
    streamDestroyed: ({ reason }: { reason: String }) => {
      console.log(`Publisher stream destroyed because: ${reason}`);
    },
  };

  const subscriberEventHandlers = {
    connected: () => {
      setOtherPersonConnected(true);
      props.startTimer();
    },
    disconnected: () => {
      setOtherPersonConnected(false);
      props.stopTimer();
    },
    destroyed: () => {
      setOtherPersonConnected(false);
      props.stopTimer();
    },
    videoEnabled: () => {
      setIsMyDatesVideoEnabled(true);
    },
    videoDisabled: () => {
      setIsMyDatesVideoEnabled(false);
    },
  };

  return (
    <OTSession
      ref={otSession}
      apiKey={data.vonageApiKey}
      token={data.vonageToken}
      sessionId={data.vonageSessionId}
      eventHandlers={sessionEventHandlers}
    >
      {permissionsDenied && (
        <PermissionDenied>
          You have denied access to your camera and/or microphone. To join the
          date please enable these permissions.
        </PermissionDenied>
      )}
      <VideoHolder display={otherPersonConnected ? 'flex' : 'none'}>
        <MySide>
          <OTPublisher
            properties={{
              publishVideo: props.publishVideo,
              publishAudio: props.publishAudio,
              width: '100%',
              height: '330px',
              showControls: false,
            }}
            onPublish={onPublish}
            onError={onError}
            eventHandlers={publisherEventHandlers}
          />
          {!props.publishVideo && (
            <VideoPausedOverlay
              photo={props.data.myThumbnailUrl}
              myVideoPaused
            />
          )}
        </MySide>
        <TheirSide>
          <OTStreams>
            <OTSubscriber
              properties={{
                width: '100%',
                height: '330px',
                showControls: false,
              }}
              onSubscribe={onSubscribe}
              onError={onError}
              eventHandlers={subscriberEventHandlers}
            />
          </OTStreams>
          {!isMyDatesVideoEnabled && (
            <VideoPausedOverlay photo={props.data.thumbnailUrl} />
          )}
        </TheirSide>
      </VideoHolder>
    </OTSession>
  );
};

type VideoHolderProps = {
  display: string;
};
const VideoHolder = styled.div<VideoHolderProps>(
  {
    justifyContent: 'center',
  },
  (props: any) => ({ display: props.display })
);

const MySide = styled.div`
  position: relative;
  height: 330px;
  width: 450px;
  margin: 0 20px;
`;

const TheirSide = styled.div`
  position: relative;
  height: 330px;
  width: 450px;
  margin: 0 20px;
`;

const PermissionDenied = styled.div`
  position: absolute;
  width: 400px;
  left: calc(50vw - 200px);
  top: 20px;
  border-radius: 15px;
  padding: 15px;
  color: white;
  font-weight: bold;
  background-color: ${Colours.default};
  z-index: 10;
`;

export default Video;
