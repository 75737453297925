import React, { useState } from 'react';
import Button from '../Button'

interface Props {
  handleSubmit: (issue: string) => void;
}

const DateDidntHappen = (props: Props) => {
  const [issue, setIssue] = useState('');

  const onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    setIssue(e.currentTarget.value);
  }

  return (
    <>
      <p>Oh no! Why didn’t this date happen?</p>
      <textarea value={issue} onChange={onChange} />
      <Button onClick={() => props.handleSubmit(issue)}>Submit</Button>
    </>
  );
};

export default DateDidntHappen;
