import React from 'react';
import styled from '@emotion/styled';

interface HeaderProps {
  children: any;
}

const Header = (props: HeaderProps) => {
  return (
    <HeaderDiv>
      {props.children}
    </HeaderDiv>
  )
 };

 const HeaderDiv = styled.div `
   font-weight: bold;
   margin: 32px 0;
 `

export default Header;
