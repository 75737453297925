import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import styled from '@emotion/styled';
import Colours from '../Styles/Colours';
import Logo from '../assets/logo.png';
import StateManager from '../Components/StateManager';
import { toCamel } from 'snake-camel';
import { ServerPayload } from '../Types';

const Home = () => {
   const token = window.location.pathname.slice(1);
   const [data, setData] = useState<any>(null);

  useEffect(() => {
    axios
      .get<any, AxiosResponse>(
        `${process.env.REACT_APP_API_ENDPOINT}browser_video_dates/${token}`
      )
      .then((result) => {
        if (result.data) {
          setData(toCamel(result.data) as ServerPayload);
        }
      });
  }, [token]);

  return (
    <Container>
      <Header>
        <StyledImage src={Logo} alt="Intro logo" />
      </Header>
      <Content>
        {data && (<StateManager data={data} />)}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${Colours.default};
  min-height: 100vh;
`;

const Header = styled.div`
  padding: 80px 0;
`;

const Content = styled.div`
`;

const StyledImage = styled.img `
  width: 290px;
  display: block;
  margin: auto;
`;

export default Home;
