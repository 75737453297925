import React from 'react';
import styled from '@emotion/styled';
import WhiteRectangle from './WhiteRectangle';
import Button from './Button';
import Colours from '../Styles/Colours';

interface QuitProps {
  cancelOut: () => void;
  tempQuit: () => void;
  permaQuit: () => void;
}

const QuitOptions = (props: QuitProps) => {
  return (
    <Holder onClick={props.cancelOut}>
      <WhiteRectangle>
        <Button onClick={props.permaQuit}>We've finished the date</Button>
        <Button onClick={props.tempQuit}>I'm coming back</Button>
      </WhiteRectangle>
    </Holder>
  )
}

const Holder = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${Colours.defaultSemiTransparent};
  z-index: 10;
`;


export default QuitOptions;
