import React from 'react';
import ThumbsUpIcon from '../../assets/smallthumbsup.svg';
import ThumbsDownIcon from '../../assets/smallthumbsdown.svg';
import VideoIcon from '../../assets/video.svg';

interface Props {
  message: string;
  positive?: boolean;
  video?: boolean;
}

const FeedbackCrumbs = (props: Props) => {
  return (
    <p>
      {props.video && <img src={VideoIcon} alt="" />}
      {props.positive && <img src={ThumbsUpIcon} alt="" />}
      {props.positive === false && <img src={ThumbsDownIcon} alt="" />}{' '}
      {props.message}
    </p>
  );
};

export default FeedbackCrumbs;
