import styled from '@emotion/styled';
import Colours from '../Styles/Colours';

const TextButton = styled.button `
  border: 0;
  outline: none;
  cursor: pointer;
  background: transparent;
  color: ${Colours.default};
  text-align: left;
  display: block;
  font-size: 14px;
`;

export default TextButton;
