import React, { useState, useRef, useEffect } from 'react';
import '@opentok/client';
import { ServerPayload } from '../Types';
import Buttons from './Buttons';
import styled from '@emotion/styled';
import PreDateContent from './PreDateContent';
import Header from './Header';
import BackIcon from '../assets/back.svg';
import Video from './Video';
import Timer from './Timer';
import QuitOptions from './QuitOptions';
import axios from 'axios';

interface ConnectionProps {
  data: ServerPayload;
  extendable: boolean;
  goBack: () => void;
  endDate: (extend: boolean) => void;
  remainingSeconds: number;
  subtractFromRemainingSeconds: (elapsed: number) => void;
}

const ConnectToVideoProvider = (props: ConnectionProps) => {
  const [publishVideo, setPublishVideo] = useState<boolean>(true);
  const [publishAudio, setPublishAudio] = useState<boolean>(true);
  const [dateInProgress, setDateInProgress] = useState<boolean>(false);
  const [icebreaker, setIcebreaker] = useState<string | null>(null);
  const [
    mostRecentConnectionStartedAt,
    setMostRecentConnectionStartedAt,
  ] = useState<number | null>(null);
  const [
    sendIcebreakerIndicator,
    setSendIcebreakerIndicator,
  ] = useState<number>(0);
  const [showQuitOptions, setShowQuitOptions] = useState<boolean>(false);
  const [sendEndSignal, setSendEndSignal] = useState<number>(0);
  const [shouldOfferExtension, setShouldOfferExtension] = useState<boolean>(
    props.extendable
  );

  // Because the setInterval on the timer means some of these functions end up with out-of-date
  // values of mostRecentConnectionStartedAt otherwise. C.F. https://stackoverflow.com/a/62453660/5287957
  const recentConnectionRef = useRef<number | null>();
  recentConnectionRef.current = mostRecentConnectionStartedAt;

  const { dateId, apiKey } = props.data;

  const saveIcebreaker = (newIcebreaker: string) => {
    setIcebreaker(newIcebreaker);
    setTimeout(() => setIcebreaker(null), 5000);
  };

  const saveElapsedTime = () => {
    const secondsElapsed = calculateSecondsElapsed();
    if (secondsElapsed > 0) {
      props.subtractFromRemainingSeconds(secondsElapsed);
    }
  };

  const startTimer = () => {
    setMostRecentConnectionStartedAt(new Date().getTime());
    setDateInProgress(true);
  };

  const stopTimer = () => {
    saveElapsedTime();
    setMostRecentConnectionStartedAt(null);
    setDateInProgress(false);
  };

  const onTimeOut = () => {
    setShouldOfferExtension(true);
    setSendEndSignal(sendEndSignal + 1);
    trackEvent('left');
    setTimeout(() => {
      props.endDate(true);
    }, 100);
  };

  const manuallyEndDate = () => {
    setShouldOfferExtension(false);
    setSendEndSignal(sendEndSignal + 1);
    trackEvent('left');
    setTimeout(() => {
      props.endDate(false);
    }, 100);
  };

  const calculateSecondsElapsed = () => {
    if (!recentConnectionRef.current) return 0;
    return (new Date().getTime() - recentConnectionRef.current!) / 1000;
  };

  const goBack = () => {
    saveElapsedTime();
    trackEvent('left');
    props.goBack();
  };

  const sendIcebreaker = () => {
    setSendIcebreakerIndicator(sendIcebreakerIndicator + 1);
    trackEvent('icebreaker');
  };

  const trackEvent = (eventType: string) => {
    axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}irl_dates/${dateId}/events`,
      { event_type: eventType },
      { headers: { 'X-Api-Key': apiKey } }
    );
  };

  useEffect(() => {
    axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}irl_dates/${dateId}/events`,
      { event_type: 'joined', client: 'browser' },
      { headers: { 'X-Api-Key': apiKey } }
    );
  }, [dateId, apiKey]);

  useEffect(() => {
    // check if video camera available
    window.OT.getDevices((_, devices) => {
      if (
        devices?.filter((device) => device.kind === 'videoInput').length === 0
      ) {
        setPublishVideo(false);
      }
    });
  }, []);

  const renderHoldingContent = () => {
    return (
      <PreDateContent data={props.data}>
        <Header>Waiting for {props.data.firstName} to connect...</Header>
        <br />
        <br />
      </PreDateContent>
    );
  };

  const renderIcebreaker = () => {
    return <IcebreakerDiv>{icebreaker}</IcebreakerDiv>;
  };

  return (
    <Container>
      {props.extendable && dateInProgress && (
        <Timer
          mostRecentConnectionStartedAt={recentConnectionRef.current}
          remainingSeconds={props.remainingSeconds}
          onReachZero={onTimeOut}
        />
      )}
      <BackButton onClick={() => setShowQuitOptions(true)}>
        <StyledImage src={BackIcon} alt="Go back" />
      </BackButton>
      {showQuitOptions && (
        <QuitOptions
          cancelOut={() => setShowQuitOptions(false)}
          tempQuit={goBack}
          permaQuit={manuallyEndDate}
        />
      )}
      <Video
        data={props.data}
        publishAudio={publishAudio}
        publishVideo={publishVideo}
        startTimer={startTimer}
        stopTimer={stopTimer}
        showIcebreaker={saveIcebreaker}
        sendIcebreakerIndicator={sendIcebreakerIndicator}
        terminateDate={props.endDate}
        sendEndSignal={sendEndSignal}
        extend={shouldOfferExtension}
      />
      {!dateInProgress && renderHoldingContent()}
      {icebreaker && renderIcebreaker()}

      <Buttons
        thumbnailUrl={props.data.thumbnailUrl}
        enabled={true}
        videoOn={publishVideo}
        audioOn={publishAudio}
        onVideoClick={() => {
          setPublishVideo(!publishVideo);
        }}
        onAudioClick={() => setPublishAudio(!publishAudio)}
        onIcebreakerClick={sendIcebreaker}
        showIcebreakerTooltip={true}
        apiKey={apiKey}
        dateId={dateId}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const IcebreakerDiv = styled.div`
  position: absolute;
  top: -120px;
  left: 0;
  right: 0;
  width: 600px;
  font-weight: bold;
  max-width: 95%;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  margin: auto;
`;

const BackButton = styled.button`
  position: absolute;
  top: -120px;
  right: 50px;
  width: 40px;
  height: 40px;
  outline: none !important;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

export default ConnectToVideoProvider;
