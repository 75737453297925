import styled from '@emotion/styled';

type ProfileImageProps = {
  thumbnailUrl: string
}

const ProfileImage = styled.div<ProfileImageProps>(
  {
    width: '104px',
    height: '104px',
    display: 'block',
    margin: 'auto',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '4px solid white',
    borderRadius: '52px',
    boxShadow: '0px 5px 50px #001A7033'
  },
  (props: ProfileImageProps) => ({ backgroundImage: `url(${props.thumbnailUrl});` })
);

export default ProfileImage
