import React, { useState, useEffect } from 'react';
import FirstStep from './FeedbackSteps/FirstStep';
import VideoExperience from './FeedbackSteps/VideoExperience';
import DateIssues from './FeedbackSteps/DateIssues';
import DateDidntHappen from './FeedbackSteps/DateDidntHappen';
import VideoInput from './FeedbackSteps/VideoInput';
import RescheduleDate from './FeedbackSteps/RescheduleDate';
import FinalScreen from './FeedbackSteps/FinalScreen';
import FeedbackCrumbs from './FeedbackSteps/FeedbackCrumbs';
import Note from './FeedbackSteps/Note';
import { ServerPayload } from '../Types';
import BackIcon from '../assets/backchevron.svg';
import WhiteRectangle from './WhiteRectangle';
import ProfileImage from './ProfileImage';
import Buttons from './Buttons';
import styled from '@emotion/styled';
import axios from 'axios';

interface Props {
  data: ServerPayload;
}

type Initial = {
  tag: 'Initial';
  data: {};
};

type NoSecondDate = {
  tag: 'NoSecondDate';
  data: { second_date: false };
};

type AddNote = {
  tag: 'AddNote';
  data: { second_date: false; match_feedback: string | null };
};

type DidntHappen = {
  tag: 'DidntHappen';
  data: { second_date: null; no_date: true };
};

type DateReschedule = {
  tag: 'Reschedule';
  data: { second_date: null; no_date: true; no_date_explanation: string };
};

type VideoRating = {
  tag: 'VideoRating';
  data:
    | { second_date: true }
    | {
        second_date: false;
        match_feedback: string | null;
        note: string | null;
      };
};

type DifferentConnection = {
  tag: 'DifferentConnection';
  data:
    | { second_date: true; venue_score: null }
    | {
        second_date: false;
        match_feedback: string | null;
        note: string | null;
        venue_score: null;
      };
};

type VideoProblems = {
  tag: 'VideoProblems';
  data:
    | { second_date: true; venue_score: 1 }
    | {
        second_date: false;
        match_feedback: string | null;
        note: string | null;
        venue_score: 1;
      };
};

type ThankYou = {
  tag: 'ThankYou';
  data: any;
};

type State =
  | Initial
  | VideoRating
  | NoSecondDate
  | DidntHappen
  | DateReschedule
  | DifferentConnection
  | VideoProblems
  | ThankYou
  | AddNote;

interface FeedbackProps {
  data: ServerPayload;
}

const Feedback = (props: FeedbackProps) => {
  const [state, setState] = useState<State>({ tag: 'Initial', data: {} });
  const [stateHistory, setStateHistory] = useState<State[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const data = props.data;
  useEffect(() => {
    const savedStateIndex = stateHistory.findIndex(
      savedState => savedState.tag === state.tag
    );
    if (savedStateIndex < 0) {
      setStateHistory([...stateHistory, state]);
    }
  }, [state, stateHistory]);

  const submitPayload = () => {
    if (!submitted) {
      submitToServer(state.data);
      setSubmitted(true);
    }
  };

  const submitToServer = (payload: any) => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}irl_dates/${data.dateId}/post_date_feedbacks`,
        payload,
        { headers: { 'X-Api-Key': data.apiKey }}
      )
  };

  const renderSteps = () => {
    switch (state.tag) {
      case 'Initial':
        return (
          <FirstStep
            firstName={data.firstName}
            handleDidntHappen={() =>
              setState({
                tag: 'DidntHappen',
                data: { second_date: null, no_date: true },
              })
            }
            handleThumbsDown={() =>
              setState({ tag: 'NoSecondDate', data: { second_date: false } })
            }
            handleThumbsUp={() =>
              setState({ tag: 'VideoRating', data: { second_date: true } })
            }
          />
        );
      case 'VideoRating':
        return (
          <VideoExperience
            handleDifferentConnection={() =>
              setState({
                tag: 'DifferentConnection',
                data: { ...state.data, venue_score: null },
              })
            }
            handleThumbsDown={() =>
              setState({
                tag: 'VideoProblems',
                data: { ...state.data, venue_score: 1 },
              })
            }
            handleThumbsUp={() => {
              setState({
                tag: 'ThankYou',
                data: { ...state.data, venue_score: 5 },
              });
            }}
          />
        );
      case 'NoSecondDate':
        return (
          <DateIssues
            handleComplete={issue =>
              setState({
                tag: 'AddNote',
                data: { ...state.data, match_feedback: issue },
              })
            }
          />
        );
      case 'DidntHappen':
        return (
          <DateDidntHappen
            handleSubmit={ (reason: string) => {
              setState({
                tag: 'Reschedule',
                data: {
                  second_date: null,
                  no_date: true,
                  no_date_explanation: reason,
                },
              })
            }
          }
          />
        );
      case 'DifferentConnection':
        return (
          <VideoInput
            handleSubmit={(issue: string) => {
              setState({
                tag: 'ThankYou',
                data: { ...state.data, alternative_connection: issue },
              });
            }}
            message={'You connected in a different way, tell us why...'}
          />
        );
      case 'VideoProblems':
        return (
          <VideoInput
            message="Sorry to hear that. What was the problem?"
            handleSubmit={feedback => {
              setState({
                tag: 'ThankYou',
                data: { ...state.data, venue_feedback: feedback },
              });
            }}
          />
        );
      case 'Reschedule':
        return (
          <RescheduleDate
            handleDismiss={() =>
              setState({ tag: 'ThankYou', data: { ...state.data } })
            }
            handleScheduleVideo={() =>
              setState({ tag: 'ThankYou', data: { ...state.data, reschedule: true } })
            }
            name={data.firstName ?? ''}
          />
        );

      case 'AddNote':
        return (
          <Note
            buttonLabel="Continue"
            theirName={data.firstName}
            handleSubmit={note => {
              setState({
                tag: 'VideoRating',
                data: { ...state.data, note: note },
              });
            }}
          />
        );
    }
  };

  const renderThankYou = () => (
    <>
      <FinalScreen
        onShow={submitPayload}
      >
        {state.data?.second_date === true && (
          <FeedbackCrumbs
            message={`Want to keep messaging ${data.firstName}.`}
            positive
          />
        )}
        {state.data?.second_date === false && (
          <FeedbackCrumbs
            message={`Don't want to keep messaging ${data.firstName}.`}
            positive={false}
          />
        )}
        {state.data?.venue_score === 5 && (
          <FeedbackCrumbs
            message={`Great video date experience`}
            video
          />
        )}
        {state.data?.venue_score === 1 && (
          <FeedbackCrumbs
            message={`Poor video experience`}
            video
          />
        )}
      </FinalScreen>
    </>
  );

  return (
    <>
      <WhiteRectangle>
        {state.tag !== 'Initial' && state.tag !== 'ThankYou' && (
          <BackButton onClick={() => {
            if (stateHistory.length >= 2) {
                setStateHistory(stateHistory.slice(0, -1));
                setState(stateHistory[stateHistory.length - 2]);
              }
            }}>
            <StyledImage src={BackIcon} alt='Go back' />
          </BackButton>)}


        {state.tag !== 'ThankYou' ? (
          <>
            <ProfileImage thumbnailUrl={data.thumbnailUrl } />
            <br/>
            <br/>
            {renderSteps()}
          </>
        ) : (
          renderThankYou()
        )}

      </WhiteRectangle>
      <Buttons thumbnailUrl={data.thumbnailUrl} enabled={false}></Buttons>
    </>
  );
};

const BackButton = styled.button `
  position: absolute;
  top: 20px;
  left: 50px;
  width: 40px;
  height: 40px;
  outline: none !important;
  background: transparent;
  border: none;
  cursor: pointer;
`

const StyledImage = styled.img `
  width: 100%;
  height: 100%;
`

export default Feedback;
