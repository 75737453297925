import React from 'react';
import styled from '@emotion/styled'
import Colours from '../Styles/Colours';

interface ButtonProps {
  onClick: () => void;
  children: any;
  buttonStyle?: string;
}

const Button = (props: ButtonProps) => {
  return (
    <StyledButton buttonStyle={props.buttonStyle} onClick={props.onClick}>
      {props.children}
    </StyledButton>
  )
 };

type StyledButtonProps = {
  buttonStyle?: string
}

const StyledButton = styled.button<StyledButtonProps>(
  {
    padding: '1.5rem',
    color: 'white',
    borderRadius: '4px',
    fontWeight: 'bold',
    border: 0,
    cursor: 'pointer',
    width: '222px',
    margin: '10px 0',
  },
  (props: StyledButtonProps) => {
    if (props.buttonStyle === 'secondary') {
      return { backgroundColor: Colours.disabled, color: Colours.default };
    } else {
      return { backgroundColor: Colours.default, color: 'white' };
    }
  }
);

export default Button;



