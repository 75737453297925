import React, { useState } from 'react';
import styled from '@emotion/styled'
import CameraIcon from '../assets/camera.svg';
import MicIcon from '../assets/microphone.svg';
import IcebreakerIcon from '../assets/icebreaker.svg';
import Colours from '../Styles/Colours';
import axios from 'axios';

interface ButtonProps {
  thumbnailUrl: string;
  enabled: boolean;
  videoOn?: boolean;
  audioOn?: boolean;
  onVideoClick?: () => void;
  onAudioClick?: () => void;
  onIcebreakerClick?: ()=> void;
  showIcebreakerTooltip?: boolean;
  apiKey?: string;
  dateId?: number;
}

const Buttons = (props: ButtonProps) => {
  const VideoButton = styled.button((props.enabled && props.videoOn) ? enabledStyle : disabledStyle);
  const AudioButton = styled.button((props.enabled && props.audioOn) ? enabledStyle : disabledStyle);
  const StyledButton = styled.button(props.enabled ? enabledStyle : disabledStyle);

  const [showIcebreakerTooltip, setShowIcebreakerTooltip] = useState<boolean>(props.showIcebreakerTooltip || false);
  setTimeout(() => setShowIcebreakerTooltip(false), 3000);

  const [showProfileTooltip, setShowProfileTooltip]  = useState<boolean>(false);

  const flashProfileTooltip = () => {
    setShowProfileTooltip(true);

    axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}irl_dates/${props.dateId}/pushes`,
      {},
      { headers: { 'X-Api-Key': props.apiKey }}
    );
    setTimeout(() => setShowProfileTooltip(false), 3000);
  }


  return (
    <Container>
      <AudioButton disabled={!props.enabled} onClick={props.onAudioClick}>
         <StyledImage src={MicIcon} alt='Toggle microphone' />
      </AudioButton>
      <VideoButton disabled={!props.enabled} onClick={props.onVideoClick}>
         <StyledImage src={CameraIcon} alt='Toggle camera' />
      </VideoButton>
      <StyledButton disabled={!props.enabled} onClick={props.onIcebreakerClick}>
         {showIcebreakerTooltip && <Tooltip>Lost for words? Try an Icebreaker</Tooltip>}
         <StyledImage src={IcebreakerIcon} alt='Launch icebreaker' />
      </StyledButton>
      <StyledButton disabled={!props.enabled} onClick={flashProfileTooltip}>
         {showProfileTooltip && <Tooltip>View profile in The Intro app</Tooltip>}
         <ProfileImage src={props.thumbnailUrl} alt='Profile' />
      </StyledButton>
    </Container>
  )
 };

const Container = styled.div`
  width: 320px;
  text-align: center;
  margin: 100px auto 0;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
`

const StyledImage = styled.img `
  width: 100%;
  height: 100%;
`

const ProfileImage = styled.img `
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 2px solid white;
`

const enabledStyle = `
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  display: inline-block;
  outline: none !important;
  position: relative;
  cursor: pointer;
`

const disabledStyle = `
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  opacity: 0.5;
  display: inline-block;
  outline: none !important;
  position: relative;
`;

const Tooltip = styled.div `
  position: absolute;
  display: inline-block;
  top: -40px;
  right: 0;
  background-color: white;
  color: ${Colours.default};
  padding: 5px 3px;
  border-radius: 3px;
  width: 300px;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid white;
    position: absolute;
    bottom: -5px;
    right: 15px;
  }
`;



export default Buttons;



