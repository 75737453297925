import React from 'react';
import styled from '@emotion/styled';
import Colours from '../Styles/Colours';

interface WhiteRectangleProps {
  children: any;
}

const WhiteRectangle = (props: WhiteRectangleProps) => {
  return (
    <WhiteRectangleDiv>
      {props.children}
    </WhiteRectangleDiv>
  )
 };

 const WhiteRectangleDiv = styled.div `
   width: 412px;
   max-width: 95%;
   text-align: center;
   padding: 20px 40px;
   border-radius: 20px;
   background-color: white;
   margin: auto;
   position: relative;

   & > p {
     font-size: 14px;
     margin-bottom: 15px;
   }

   & > textarea {
     width: 100%;
      height: 100px;
      padding: 5px;
      color: ${Colours.default};
   }
 `

export default WhiteRectangle;
