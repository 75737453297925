import styled from '@emotion/styled';
import React from 'react';
import Colours from '../Styles/Colours';

interface Props {
  photo: string;
  myVideoPaused?: boolean;
}

const VideoPausedOverlay: React.FC<Props> = (props) => {
  const renderMyVideoPausedIcon = () => (
    <svg
      style={{ zIndex: 2 }}
      width="47"
      height="46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.77 9.583h6.56a3.927 3.927 0 013.926 3.926v6.56l1.964 1.964 11.783-8.524v19.64m-13.75-1.964v1.963a3.929 3.929 0 01-3.926 3.937H5.725a3.926 3.926 0 01-3.927-3.937V13.51a3.927 3.927 0 013.927-3.926h3.93l21.599 21.602zM1.798 1.727L45 44.929"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const renderMyDatesVideoPausedIcon = () => (
    <svg
      width="100"
      height="100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ zIndex: 2 }}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M50.001 0a50.001 50.001 0 110 100.003A50.001 50.001 0 0150.001 0z"
          fill="#F0F1F5"
        />
        <path
          d="M46.77 35.583h6.56a3.927 3.927 0 013.926 3.927v6.56l1.964 1.963 11.783-8.523v19.638m-13.75-1.963v1.963a3.929 3.929 0 01-3.926 3.937H31.725a3.927 3.927 0 01-3.927-3.937V39.51a3.927 3.927 0 013.927-3.926h3.93l21.599 21.602zM27.798 27.727L71 70.929"
          stroke="#001A70"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h100v100H0z" />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <Container>
      <Image src={props.photo} alt="My camera disabled" />
      <BlueSmiTransparentOverlay />
      {props.myVideoPaused
        ? renderMyVideoPausedIcon()
        : renderMyDatesVideoPausedIcon()}
      {!props.myVideoPaused && <Text>Video Paused</Text>}
    </Container>
  );
};

const Image = styled.img`
  width: 100%;
  filter: blur(3px);
  height: 330px;
  position: absolute;
  top: 0;
  z-index: 0;
  object-fit: cover;
`;

const Container = styled.div`
  width: 100%;
  height: 330px;
  position: absolute;
  z-index: 100;
  background-color: ${Colours.default};
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
`;

const BlueSmiTransparentOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colours.defaultSemiTransparent};
  top: 1;
  z-index: 1;
  position: absolute;
`;

const Text = styled.span`
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
  z-index: 2;
  margin-top: 1rem;
`;

export default VideoPausedOverlay;
