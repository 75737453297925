import React from 'react';
import WhiteRectangle from './WhiteRectangle';
import ProfileImage from './ProfileImage';
import { ServerPayload } from '../Types';
import styled from '@emotion/styled';

interface PreDateContentProps {
  data: ServerPayload;
  children: any;
}

const PreDateContent = (props: PreDateContentProps) => {

  return (
    <WhiteRectangle>
      <ProfileImage thumbnailUrl={props.data.thumbnailUrl} />
      {props.children}
      <Ordered>
        <li>Your date will last 30 minutes</li>
        <li>Choose whether to extend it</li>
        <li>Decide if you want to keep in touch</li>
      </Ordered>
    </WhiteRectangle>
  );
 };

 const Ordered = styled.ol `
   width: 242px;
   margin: 10px auto;
   text-align: left;
   padding-left: 20px;
   font-size: 13px;
 `;

export default PreDateContent;
