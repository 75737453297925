import React from 'react';
import ThumbsUpIcon from '../../assets/thumbsup.svg';
import ThumbsDownIcon from '../../assets/thumbsdown.svg';
import ThumbButton from '../ThumbButton';
import TextButton from '../TextButton';

interface Props {
  handleDifferentConnection?: () => void;
  handleThumbsUp: () => void;
  handleThumbsDown: () => void;
}

const VideoExperience = (props: Props) => {
  return (
    <>
      <p>How was The Intro’s video date experience?</p>
      <div>
        <ThumbButton onClick={props.handleThumbsDown} >
          <img src={ThumbsDownIcon} alt="Thumbs down" />
        </ThumbButton>
        <ThumbButton onClick={props.handleThumbsUp} >
          <img src={ThumbsUpIcon} alt="Thumbs up"/>
        </ThumbButton>
      </div>
      <TextButton onClick={props.handleDifferentConnection}>
        We connected in a different way ›
      </TextButton>
    </>
  );
};

export default VideoExperience;
