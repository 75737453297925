import styled from '@emotion/styled';

const ThumbButton = styled.button `
  border: 0;
  outline: none;
  cursor: pointer;
  background: transparent;
  height: 100px;
  width: 100px;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export default ThumbButton;
