import React from 'react';
import Button from '../Button'

interface Props {
  handleScheduleVideo: () => void;
  handleDismiss: () => void;
  name: string;
}

const RescheduleDate = (props: Props) => {
  return (
    <>
      <p>This date didn't happen.</p>
      <p>Do you want to try scheduling another date with {props.name}</p>

      <Button onClick={props.handleScheduleVideo}>Yes</Button>
      <Button buttonStyle='secondary' onClick={props.handleDismiss}>No</Button>
    </>
  );
};

export default RescheduleDate;
