import React, { useState } from 'react';
import { ServerPayload } from '../Types';
import Initial from './Initial';
import Extend from './Extend';
import Feedback from './Feedback';
import ConnectToVideoProvider from './ConnectToVideoProvider';

interface StateManagerProps {
  data: ServerPayload
}

const StateManager = (props: StateManagerProps) => {
  const [screen, setScreen] = useState<string>('initial');
  const [remainingSeconds, setRemainingSeconds] = useState<number>(props.data.videoCallLengthInMinutes * 60);
  const [extended, setExtended] = useState<boolean>(false);
  const subtractFromRemainingSeconds = (elapsed: number) => {
    setRemainingSeconds(remainingSeconds - elapsed);
  }

  const endDate = (extend: boolean) => {
    setExtended(true);
    setScreen(extend ? 'extension' : 'feedback')
  }

  const makeExtensionChoice = (extend: boolean) => {
    setScreen(extend ? 'video' : 'feedback')
  }

  switch (screen) {
    case 'initial':
    case 'reinitial':
      return (<Initial data={props.data} resume={screen==='reinitial'} startDate={()=>setScreen('video')}/>)
    case 'video':
      return (<ConnectToVideoProvider
                     data={props.data}
                     extendable={!extended}
                     endDate={endDate}
                     goBack={()=>setScreen('reinitial')}
                     remainingSeconds={remainingSeconds}
                     subtractFromRemainingSeconds={subtractFromRemainingSeconds}
              />)
    case 'extension':
      return (<Extend data={props.data} result={makeExtensionChoice} />);
    case 'feedback':
      return (<Feedback data={props.data} />)
    default:
      return null
   }
 };

export default StateManager;
