import React, { useEffect } from 'react';
import TickIcon from '../../assets/tick.svg';
import styled from '@emotion/styled';

interface Props {
  children: any;
  onShow: () => void;
}

const FinalScreen = (props: Props) => {
  const onShow = props.onShow
  useEffect(() => {
    onShow();
  }, [onShow]);

  return (
    <>
      <Icon src={TickIcon} alt=""/>
      <p>Thank you!</p>
      {props.children}
    </>
  );
};

const Icon = styled.img `
  display: inline-block;
  margin-right: 5px;
`

export default FinalScreen;
